export function changeLocale(locale) {
    return {
        type: 'CHANGE_LOCALE',
        payload: locale
    }
}

export function changeRegion(region) {
    return {
        type: 'CHANGE_REGION',
        payload: region
    }
}
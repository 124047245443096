export default {
  /* HOME */

  'home.title': 'We are a digital\nproduct studio.',
  'home.title_mobile': 'We are a\ndigital product\nstudio.',
  'home.featured': 'Featured Projects',

  'home.services.strategy': 'Strategy',
  'home.services.design': 'Creativity',
  'home.services.development': 'Technology',

  'home.services.ux_consultancy': 'UX Consultancy',
  'home.services.user_research': 'User Research',
  'home.services.user_testing': 'User Testing',
  'home.services.analytics': 'SEO & ASO',
  'home.services.monetisation': 'Monetisation',
  'home.services.gamification': 'Gamification',
  'home.services.wireframing': 'Wireframing & Prototyping',
  'home.services.user_experience': 'User Experience',
  'home.services.web_app_design': 'Web & App Design',
  'home.services.illustration': 'Illustration',
  'home.services.motion_design': 'Motion Design',
  'home.services.branding': 'Branding & Identity',
  'home.services.copywriting': 'Copywriting',
  'home.services.ios': 'iOS Apps',
  'home.services.android': 'Android Apps',
  'home.services.web': 'Websites & Web Apps',
  'home.services.vr': 'VR & AR',
  'home.services.hosting': 'Hosting',
  'home.services.maintenance': 'Maintenance & Support',

  'home.what_we_do.title': 'What we do',
  'home.what_we_do.description':
    'We are a digital product studio that loves to help businesses grow. We turn your original concept into a striking digital solution developed from scratch just for you.',

  'home.studio.title': 'You are part\nof our team',
  'home.studio.description':
    'We see each project as one of our own to ensure the best outcome. We love to have you be a part of the team and follow our process every step of the way.',
  'home.studio.button': 'Meet the studio',

  /* HOME/PROJECTS */

  'london.title': 'Your Future London',
  'london.description': 'Bulding an\nexciting future',
  'london.services': 'coding',

  'myos.title': 'MYOS',
  'myos.description': 'Making it easier\nto shop',
  'myos.services': 'design, coding',

  'guessup.title': 'GuessUp',
  'guessup.description': 'Bringing back\ncharades',
  'guessup.services': 'branding, design, coding',

  'chocolatebox.title': 'Chocolate Box',
  'chocolatebox.description': 'Creating surprising\nways of traveling',
  'chocolatebox.services': 'design, coding',

  'home.view_project': 'View project',

  /* STUDIO */

  'studio.title': 'We code to make\na difference',

  'studio.culture.title': 'Our culture',
  'studio.culture.description':
    'We believe in happy clients. To achieve that, we strive to keep innovating. ' +
    'We believe that only by working with integrity and building honest and lasting relationships can we keep up to the standards our customers deserve.\n\n' +
    'We wake up early and stay up late, working hard everyday. Why? Because we believe the best is still not good enough for you.',

  'studio.how_we_do_it.title': 'How we do it',
  'studio.how_we_do_it.description':
    'We have designed a 5-step process that allows us to predict and avoid any miscommunication issues that could arise during the development of your project.',

  'studio.how_we_do_it.strategy.title': 'Strategy',
  'studio.how_we_do_it.0.description':
    'First things first, we ask the questions that will help to bring your idea to life. Together, we plan the entire project from concept up until launching, and show you how to beat the competition and monetize your project in the best way possible.',
  'studio.how_we_do_it.wireframe.title': 'Wireframe',
  'studio.how_we_do_it.1.description':
    'Thinking about your final customer, we’ll design a low-fidelity design that focuses on user experience. We’ll create not only a product that people need to use, but a product that people enjoy using.',
  'studio.how_we_do_it.design.title': 'Design',
  'studio.how_we_do_it.2.description':
    "Now that the user experience is aligned, it is time to give life to the static wireframes. We take your brand's identity and turn the first wireframes into a stunning visual experience.",
  'studio.how_we_do_it.development.title': 'Coding',
  'studio.how_we_do_it.3.description':
    'This is where you see the design being brought to life. With everyone working on the same team we will give an already beautiful design the performance it deserves.',
  'studio.how_we_do_it.launch.title': 'Launch',
  'studio.how_we_do_it.4.description':
    'Now it’s time to show it to the world. Together, we think of the best way to communicate your project and plan out for the future.',

  'studio.globally.title': 'We think\nglobally',
  'studio.globally.description':
    'Aware of the world’s interchangeability, we develop each project having in mind its potential global reach. ' +
    'Our web and mobile apps are used every month by thousands of people across the globe.',

  'studio.who_we_are.title': 'Who we are',
  'studio.who_we_are.description':
    'Founded in 2016 by three ambitious friends in Porto, Portugal, we are committed to changing the way you interact with the digital world. Counting on the multiple partnerships we have made over time, we can get everything your business needs to grow steadily.',

  /* CONTACTS */

  'contacts.title': 'Ready to work with us?',
  'contacts.quote': 'Request a quote',
  'contacts.body': 'Our door is\nalways open to\ngreat ideas.',

  /* HEADER */

  'header.studio': 'Studio',
  'header.contacts': 'Contacts',

  /* REQUEST A QUOTE */

  'quote.title': 'Request a quote',
  'quote.description':
    'We are always open to collaborations where we can all be a part of the same team. Ready to move forward?',
  'quote.tell_us_yourself': 'Tell us about yourself',
  'quote.name': 'Name',
  'quote.email': 'Email',
  'quote.company': 'Company',
  'quote.phone': 'Phone',
  'quote.tell_us_project': 'Tell us about your project',
  'quote.description_project': 'Give us a description of your project',
  'quote.what_you_need': 'What will we need to do?',
  'quote.mobile_app': 'Mobile App',
  'quote.website': 'Website',
  'quote.design': 'Design',
  'quote.other': 'Other',
  'quote.budget': 'What is your approximate budget?',
  'quote.launch': 'When are you planning to launch?',
  'quote.minus_3_months': '< 3 months',
  'quote.3_6_months': '3-6 months',
  'quote.6_9_months': '6-9 months',
  'quote.plus_9_months': '> 9 months',
  'quote.discover': 'How did you hear about Cosmicode?',
  'quote.search_engine': 'Search Engine',
  'quote.recommendation': 'Recommendation',
  'quote.social_network': 'Social Network',
  'quote.send_request': 'Send request',

  /* FOOTER */

  'footer.title': "Let's create\nsomething together!",
  'footer.contact': 'Contact us',
  'footer.project': 'Work with us',
  'footer.follow': 'Follow us',
  'footer.quote': 'Request a quote',
  'footer.copyright': 'Cosmicode. We code things.'
};

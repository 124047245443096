import React, { Component } from "react";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import App from "./index";
import { appLocales, defaultLanguage } from "../../lang";
import { CSSTransition, TransitionGroup } from "react-transition-group";

import Home from "../Home";
import Studio from "../Studio";
import Contacts from "../Contacts";
import NotFound from "../404";
import Header from "../../components/navigation/Header";
import MobileMenu from "../../components/navigation/MobileMenu";

const PageFade = (props) => (
  <CSSTransition
    {...props}
    classNames="fade"
    timeout={300}
    mountOnEnter={true}
    unmountOnExit={true}
  />
);

class Routes extends Component {
  _detectIE() {}

  render() {
    let userLocale = navigator.language || navigator.userLanguage || "en";
    let locale = userLocale.toLowerCase().split(/[_-]+/)[0];

    if (appLocales.indexOf(locale) === -1) {
      locale = defaultLanguage;
    }

    if (appLocales.length > 1) {
      return (
        <Switch>
          <Route exact path="/" render={() => <Redirect to={locale} />} />
          <Route path="/:lang">
            <App>
              <div>
                <Header />
                <TransitionGroup className="wrapper">
                  <PageFade key={this.props.location.pathname}>
                    <div className="wrapper-inner">
                      <Switch location={this.props.location}>
                        <Route exact path="/:lang" component={Home} />
                        <Route exact path="/:lang/studio" component={Studio} />
                        <Route
                          exact
                          path="/:lang/contacts"
                          component={Contacts}
                        />
                        <Route component={NotFound} />
                      </Switch>
                    </div>
                  </PageFade>
                </TransitionGroup>
                <MobileMenu />
              </div>
            </App>
          </Route>
        </Switch>
      );
    } else {
      return (
        <App>
          <div>
            <Header />
            <TransitionGroup className="wrapper">
              <PageFade key={this.props.location.pathname}>
                <div className="wrapper-inner">
                  <Switch location={this.props.location}>
                    <Route exact path="/" component={Home} />
                    <Route exact path="/studio" component={Studio} />
                    <Route exact path="/contacts" component={Contacts} />
                    <Route component={NotFound} />
                  </Switch>
                </div>
              </PageFade>
            </TransitionGroup>
            <MobileMenu />
          </div>
        </App>
      );
    }
  }
}

export default withRouter(Routes);

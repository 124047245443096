import en from './en';
import pt from './pt';

export const defaultLanguage = 'en';
export const appLocales = ['en', 'pt'];

export default {
  en,
  pt
};

import React, { PureComponent } from 'react';
import classnames from 'classnames';
import { Link, withRouter } from 'react-router-dom';
import Responsive from '../helpers/Responsive';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { toggleMenu } from '../../redux/actions/app';
import Animation from '../animation/Animation';
import FacebookIcon from './FacebookIcon';
import InstagramIcon from './InstagramIcon';
import DribbbleIcon from './DribbbleIcon';

const DURATION = 300;

class MobileMenu extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      animated: this.props.menuOpened,
      visible: this.props.menuOpened
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.menuOpened !== this.props.menuOpened) {
      if (!this.props.menuOpened) {
        this.setState(
          {
            animated: false
          },
          () => {
            setTimeout(() => {
              this.setState({
                visible: false
              });
            }, DURATION);
          }
        );
      } else {
        this.setState(
          {
            visible: true
          },
          () => {
            setTimeout(() => {
              this.setState({
                animated: true
              });
            }, 100);
          }
        );
      }
    }
  }

  render() {
    const { locale } = this.props;

    let classes = classnames({
      'mobile-menu': true,
      'is-open': this.state.visible
    });

    return (
      <Responsive max="landscape">
        <Animation
          className={classes}
          animation="fade"
          duration={DURATION}
          animate={this.state.animated}
          onClick={this.props.onClick}
        >
          <div className="container">
            <Link
              to={'/' + locale}
              onClick={() => {
                this.props.toggleMenu();
              }}
              className={
                this.props.location.pathname === '/' + locale
                  ? 'is-active'
                  : null
              }
            >
              Home
            </Link>
            <Link
              to={'/' + locale + '/studio'}
              onClick={() => {
                this.props.toggleMenu();
              }}
              className={
                this.props.location.pathname === '/' + locale + '/studio'
                  ? 'is-active'
                  : null
              }
            >
              Studio
            </Link>
            <Link
              to={'/' + locale + '/contacts'}
              onClick={() => {
                this.props.toggleMenu();
              }}
              className={
                this.props.location.pathname === '/' + locale + '/contacts'
                  ? 'is-active'
                  : null
              }
            >
              Contacts
            </Link>
            <div className="mobile-social">
              <a
                href="https://www.facebook.com/cosmicode.studio"
                rel="noopener noreferrer"
                target="_blank"
                className="footer-icon-wrapper"
              >
                <span className="footer-icon">
                  <FacebookIcon />
                </span>
              </a>
              <a
                href="https://www.instagram.com/cosmicode"
                rel="noopener noreferrer"
                target="_blank"
                className="footer-icon-wrapper"
              >
                <span className="footer-icon">
                  <InstagramIcon />
                </span>
              </a>
              <a
                href="https://dribbble.com/cosmicode"
                rel="noopener noreferrer"
                target="_blank"
                className="footer-icon-wrapper"
              >
                <span className="footer-icon">
                  <DribbbleIcon />
                </span>
              </a>
            </div>
          </div>
        </Animation>
      </Responsive>
    );
  }
}

const mapStateToProps = state => ({
  menuOpened: state.app.menuOpened,
  locale: state.i18n.locale
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      toggleMenu
    },
    dispatch
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MobileMenu)
);

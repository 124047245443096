import './teste';
import 'core-js/features/map';
import 'core-js/features/set';
import 'intl';
import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import store, { history } from './redux/store';
import WebFont from 'webfontloader';

// Main Component
import Routes from './containers/App/routes';

// Styles
import './assets/sass/index.css';

WebFont.load({
  custom: {
    families: ['NeueHaasGroteskDisp:n4,n5'],
  },
});

render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Routes />
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root')
);

import React, { PureComponent } from 'react';
import propTypes from 'prop-types';
import { Transition } from 'react-transition-group';

class Animation extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      animated: this.props.animate !== null ? this.props.animate : false
    };
  }

  componentDidMount() {
    if (this.props.animate === null) {
      setTimeout(() => {
        this.setState({
          animated: true
        });
      }, this.props.delay);
    }
  }

  componentDidUpdate(nextProps) {
    if (this.props.animate !== nextProps.animate) {
      setTimeout(() => {
        this.setState({
          animated: this.props.animate
        });
      }, this.props.delay);
    }
  }

  _getStyle(visible = false) {
    let defaultStyle = null;
    let style = null;

    switch (this.props.animation) {
      case 'fadeUp':
        defaultStyle = {
          transition: `all ${this.props.duration}ms ease-in-out`,
          transform: `translate3d(0, ${this.props.distance}, 0)`,
          opacity: 0
        };
        if (visible) {
          style = {
            transform: 'translate3d(0, 0, 0)',
            opacity: 1
          };
        }
        break;
      case 'fadeLeft':
        defaultStyle = {
          transition: `all ${this.props.duration}ms ease-in-out`,
          transform: `translate3d(-${this.props.distance}, 0, 0)`,
          opacity: 0
        };
        if (visible) {
          style = {
            transform: 'translate3d(0, 0, 0)',
            opacity: 1
          };
        }
        break;
      case 'fadeRight':
        defaultStyle = {
          transition: `all ${
            this.props.duration
          }ms cubic-bezier(0.42, 0, 0.01, 0.88)`,
          transform: `translate3d(${this.props.distance}, 0, 0)`,
          opacity: 0
        };
        if (visible) {
          style = {
            transform: 'translate3d(0, 0, 0)',
            opacity: 1
          };
        }
        break;
      case 'slideUp':
        defaultStyle = {
          transition: `all ${this.props.duration}ms ease-in-out`,
          transform: `translate3d(0, ${this.props.distance}, 0)`
        };
        if (visible) {
          style = {
            transform: 'translate3d(0, 0, 0)'
          };
        }
        break;
      case 'zoom':
        defaultStyle = {
          transition: `all ${this.props.duration}ms ease-in-out`,
          transform: 'scale3d(0.3, 0.3, 0.3)',
          opacity: 0
        };
        if (visible) {
          style = {
            transform: 'scale3d(1, 1, 1)',
            opacity: 1
          };
        }
        break;
      default:
        //fade
        defaultStyle = {
          transition: `all ${this.props.duration}ms ease-in-out`,
          opacity: 0
        };
        if (visible) {
          style = {
            opacity: 1
          };
        }
        break;
    }

    if (!visible) {
      style = defaultStyle;
    }

    return { defaultStyle, style };
  }

  _renderChildren() {
    return this.props.children;
  }

  render() {
    let styles = this._getStyle(this.state.animated);

    return (
      <Transition timeout={this.props.duration} in={this.state.visible}>
        {state => (
          <div
            style={{
              ...styles.defaultStyle,
              ...styles.style,
              ...this.props.style
            }}
            onClick={this.props.onClick}
            className={this.props.className}
          >
            {this._renderChildren()}
          </div>
        )}
      </Transition>
    );
  }
}

Animation.propTypes = {
  delay: propTypes.number,
  duration: propTypes.number,
  animation: propTypes.oneOf([
    'fade',
    'fadeUp',
    'fadeLeft',
    'fadeRight',
    'slideUp',
    'zoom'
  ]),
  distance: propTypes.string,
  animate: propTypes.bool
};

Animation.defaultProps = {
  delay: 0,
  duration: 1000,
  animation: 'fadeUp',
  distance: '40px',
  animate: null
};

export default Animation;

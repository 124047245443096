import React, { Component } from 'react';
import ClassNames from 'classnames';
import propTypes from 'prop-types';

class Button extends Component {
  render() {
    let classes = ClassNames(
      {
        button: true,
        'is-loading': this.props.loading
      },
      this.props.className
    );

    return (
      <button
        className={classes}
        disabled={this.props.disabled}
        onClick={this.props.onClick}
        style={this.props.style}
      >
        {this.props.children}
      </button>
    );
  }
}

Button.propTypes = {
  loading: propTypes.bool,
  disabled: propTypes.bool,
  onClick: propTypes.func
};

Button.defaultProps = {
  loading: false,
  disabled: false
};

export default Button;

export default {
  /* HOME */

  'home.title': 'Somos um estúdio\nde produtos digitais',
  'home.title_mobile': 'Somos um\nestúdio de\nprodutos digitais',
  'home.featured': 'Projetos em Destaque',

  'home.services.strategy': 'Estratégia',
  'home.services.design': 'Creatividade',
  'home.services.development': 'Tecnologia',

  'home.services.ux_consultancy': 'Consultoria UX',
  'home.services.user_research': 'Pesquisa de Mercado',
  'home.services.user_testing': 'Teste de Mercado',
  'home.services.analytics': 'SEO & ASO',
  'home.services.monetisation': 'Monetização',
  'home.services.gamification': 'Gamification',
  'home.services.wireframing': 'Wireframing & Protótipos',
  'home.services.user_experience': 'Experiência do Utilizador',
  'home.services.web_app_design': 'Design Web & App',
  'home.services.illustration': 'Ilustração',
  'home.services.motion_design': 'Motion Design',
  'home.services.branding': 'Branding & Identidade',
  'home.services.copywriting': 'Copywriting',
  'home.services.ios': 'Aplicações iOS',
  'home.services.android': 'Aplicações Android',
  'home.services.web': 'Websites & Aplicações Web',
  'home.services.vr': 'RV & RA',
  'home.services.hosting': 'Alojamento',
  'home.services.maintenance': 'Manutenção & Suporto',

  'home.what_we_do.title': 'O que fazemos',
  'home.what_we_do.description':
    'Somos um estúdio digital empenhado em ajudar projetos e empresas a crescer. Pegamos no teu conceito e, juntos, criamos uma solução digital orientada às tuas necessiades e pensada para ti.',

  'home.studio.title': 'Tu és parte\nda nossa equipa',
  'home.studio.description':
    'Queremos que faças parte do nosso processo do início ao fim. Vemos o teu projeto como se fosse nosso, de forma a assegurar sempre o melhor resultado.',
  'home.studio.button': 'Conhece a equipa',

  /* HOME/PROJECTS */

  'london.title': 'Your Future London',
  'london.description': 'Um futuro emocionante',
  'london.services': 'programação',

  'myos.title': 'MYOS',
  'myos.description': 'Compras simples\ne intuitivas',
  'myos.services': 'design, programação',

  'guessup.title': 'Guess Up',
  'guessup.description': 'As charadas\nmais divertidas',
  'guessup.services': 'branding, design, programação',

  'chocolatebox.title': 'Chocolate Box',
  'chocolatebox.description': 'Viagens surpreendentes',
  'chocolatebox.services': 'design, programação',

  'home.view_project': 'Ver projeto',

  /* STUDIO */

  'studio.title': 'Programamos para\nfazer a diferença',

  'studio.culture.title': 'Cultura',
  'studio.culture.description':
    'Acreditamos em clientes felizes. Para isso, empenhamo-nos em continuar a inovar. ' +
    'Acreditamos que trabalhar com integridade e construir relações honestas e duradouras são o caminho para atingirmos os padrões que os nossos clientes merecem.\n\n' +
    'Trabalhamos com um objetivo todos os dias: ser melhor do que os melhores. Porquê? Porque acreditamos que mesmo o melhor não é suficiente para os nossos clientes.',

  'studio.how_we_do_it.title': 'Processo',
  'studio.how_we_do_it.description':
    'O nosso processo de 5 passos permite-nos prever e evitar quaisquer falhas de comunicação que possam surgir ao longo do desenvolvimento do teu projeto.',
  'studio.how_we_do_it.strategy.title': 'Estratégia',
  'studio.how_we_do_it.0.description':
    'Primeiro, tentamos entender o que é necessário para a tua ideia ver a luz do dia. Planeamos o projeto desde o esboço até ao lançamento, e mostramos-te como derrotar a competição e motenizar o teu projeto da melhor forma.',
  'studio.how_we_do_it.wireframe.title': 'Wireframe',
  'studio.how_we_do_it.1.description':
    'A pensar no consumidor final, começamos por delinear um low-fidelity wireframe que foque a sua atenção na experiência do utilizador. Queremos criar um produto que seja útil e intuitivo.',
  'studio.how_we_do_it.design.title': 'Design',
  'studio.how_we_do_it.2.description':
    'Agora é altura de dar vida aos wireframes estáticos. Pegamos na identidade da tua marca e criamos uma experiência visual absolutamente incrível para o teu projeto.',
  'studio.how_we_do_it.development.title': 'Programação',
  'studio.how_we_do_it.3.description':
    'Esta é a etapa em que o design ganha vida. Com um só objetivo em mente, damos ao design do teu projeto a performance que merece. ',
  'studio.how_we_do_it.launch.title': 'Lançamento',
  'studio.how_we_do_it.4.description':
    'Chegou a hora de apresentar o projeto ao mundo. Juntos, pensamos a melhor forma de comunicar o teu projeto, e planeamos para o futuro.',

  'studio.globally.title': 'Pensamos globalmente',
  'studio.globally.description':
    'Atendendo à permutabilidade do mundo, desenvolvemos cada projeto tendo em mente o seu potencial a nível global. ' +
    'As nossas aplicações são utilizadas por milhares de pessoas à volta do mundo.',

  'studio.who_we_are.title': 'Quem somos',
  'studio.who_we_are.description':
    'Fundada no Porto, em 2016, por três amigos ambiciosos, a Cosmicode está empenhada em alterar a forma como interages com o mundo digital. Contando com as múltiplas parcerias que estabelecemos ao longo do tempo, garantimos tudo o que o teu projeto precisa para crescer continuamente.',

  /* CONTACTS */

  'contacts.title': 'Pronto para colaborar connosco?',
  'contacts.quote': 'Pede uma cotação',
  'contacts.body': 'Estamos sempre abertos a grandes ideias.',

  /* HEADER */

  'header.studio': 'Estúdio',
  'header.contacts': 'Contactos',

  /* REQUEST A QUOTE */

  'quote.title': 'Pede uma cotação',
  'quote.description':
    'Estamos sempre abertos a colaborações onde possamos fazer parte da mesma equipa. Pronto para avançar?',
  'quote.tell_us_yourself': 'Fala-nos de ti',
  'quote.name': 'Nome',
  'quote.email': 'Email',
  'quote.company': 'Empresa',
  'quote.phone': 'Contacto',
  'quote.tell_us_project': 'Fala-nos do teu projeto',
  'quote.description_project': 'Dá-nos uma descrição do teu projeto',
  'quote.what_you_need': 'O que precisas de nós?',
  'quote.mobile_app': 'Aplicação Móvel/Mobile',
  'quote.website': 'Website',
  'quote.design': 'Design',
  'quote.other': 'Outro',
  'quote.budget': 'Qual é o teu orçamento?',
  'quote.launch': 'Quando pretendes lançar o projeto?',
  'quote.minus_3_months': '< 3 meses',
  'quote.3_6_months': '3-6 meses',
  'quote.6_9_months': '6-9 meses',
  'quote.plus_9_months': '> 9 meses',
  'quote.discover': 'Como descobriste a Cosmicode?',
  'quote.search_engine': 'Motor de Busca',
  'quote.recommendation': 'Recomendação',
  'quote.social_network': 'Redes Sociais',
  'quote.send_request': 'Enviar pedido',

  /* FOOTER */

  'footer.title': 'Vamos criar\nalgo juntos',
  'footer.contact': 'Contacta-nos',
  'footer.project': 'Colabora connosco',
  'footer.follow': 'Segue-nos',
  'footer.quote': 'Pede uma cotação',
  'footer.copyright': 'Cosmicode. We code things.'
};

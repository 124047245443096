import React, { PureComponent } from 'react';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { Animation } from '../../components/animation';
import { Image } from '../../components/elements';
import Responsive from '../../components/helpers/Responsive';

class Project extends PureComponent {
  _renderBackElement() {
    if (this.props.class === 'myos') {
      return (
        <Responsive min="widescreen">
          <Animation
            animation="zoom"
            animate={this.props.animate}
            delay={500}
            className="donut"
          />
        </Responsive>
      );
    } else if (this.props.class === 'guessup') {
      return (
        <Responsive min="widescreen">
          <Animation
            animation="zoom"
            animate={this.props.animate}
            delay={500}
            className="square"
          />
        </Responsive>
      );
    } else if (this.props.class === 'chocolatebox') {
      return (
        <Responsive min="widescreen">
          <Animation
            animation="zoom"
            animate={this.props.animate}
            delay={500}
            className="square"
          />
        </Responsive>
      );
    } else if (this.props.class === 'london') {
      return (
        <Responsive min="widescreen">
          <Animation
            animation="zoom"
            animate={this.props.animate}
            delay={500}
            className="oval"
          >
            <Animation
              animation="zoom"
              animate={this.props.animate}
              delay={800}
              className="oval-middle"
            >
              <Animation
                animation="zoom"
                animate={this.props.animate}
                delay={1100}
                className="oval-inner"
              />
            </Animation>
          </Animation>
        </Responsive>
      );
    }
  }

  render() {
    let classes = classnames(
      {
        'project-section': true
      },
      this.props.class
    );

    return (
      <div className={classes}>
        <div className="container">
          <div className="columns">
            <Responsive max="landscape">
              <div className="column is-12 is-6-tablet">
                <Animation duration={500} animate={this.props.animate}>
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href={this.props.link}
                  >
                    <FormattedMessage id={this.props.title}>
                      {txt => (
                        <Image
                          src={this.props.image}
                          srcSet={this.props.imageSet}
                          alt={txt}
                        />
                      )}
                    </FormattedMessage>
                  </a>
                </Animation>
              </div>
            </Responsive>
            <div className="column is-12 is-6-tablet">
              <Animation duration={500} animate={this.props.animate}>
                <h2 className="project-title">
                  <FormattedMessage id={this.props.title} />
                </h2>
              </Animation>
              <Animation
                duration={500}
                delay={200}
                animate={this.props.animate}
              >
                <p className="project-description">
                  <FormattedMessage id={this.props.description} />
                </p>
              </Animation>
              <Animation
                duration={500}
                delay={400}
                animate={this.props.animate}
              >
                <p className="project-services">
                  <FormattedMessage id={this.props.services} />
                </p>
              </Animation>
              <Animation
                duration={500}
                delay={600}
                animate={this.props.animate}
              >
                <a
                  href={this.props.link}
                  rel="noopener noreferrer"
                  target="_blank"
                  className="button project-button is-black"
                >
                  <FormattedMessage id="home.view_project" />
                </a>
              </Animation>
            </div>
            <Responsive min="tablet">
              <div className="column is-12 is-6-tablet">
                <Animation duration={500} animate={this.props.animate}>
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href={this.props.link}
                  >
                    <FormattedMessage id={this.props.title}>
                      {txt => (
                        <Image
                          src={this.props.image}
                          srcSet={this.props.imageSet}
                          alt={txt}
                        />
                      )}
                    </FormattedMessage>
                  </a>
                </Animation>
              </div>
            </Responsive>
          </div>
        </div>
        {this._renderBackElement()}
      </div>
    );
  }
}

export default Project;

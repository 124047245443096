import React, { PureComponent } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { logo as logoSvg } from "../../assets/img";
import logo from "../../assets/animations/logo.json";
import Responsive from "../../components/helpers/Responsive";
import { Image } from "../../components/elements";
import { animationFinished } from "../../redux/actions/app";
import Lottie from "react-lottie";
import FacebookIcon from "./FacebookIcon";
import InstragramIcon from "./InstagramIcon";
import DribbbleIcon from "./DribbbleIcon";

class Footer extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      animationFinished: this.props.logoFinished,
    };
  }

  _renderLogo() {
    const defaultOptions = {
      loop: false,
      autoplay: false,
      animationData: logo,
    };

    if (this.state.animationFinished) {
      return (
        <div className="footer-logo-wrapper with-padding">
          <Image src={logoSvg} />
        </div>
      );
    }

    return (
      <div className="footer-logo-wrapper">
        <Lottie
          options={defaultOptions}
          width="100%"
          height="100%"
          isPaused={!this.props.visible}
          eventListeners={[
            {
              eventName: "complete",
              callback: () => {
                this.props.animationFinished();
              },
            },
          ]}
        />
      </div>
    );
  }

  render() {
    const { locale } = this.props;

    return (
      <div className="container">
        <Responsive max="landscape">
          <div className="columns force-mobile">
            <div className="column is-6">{this._renderLogo()}</div>
          </div>
        </Responsive>
        <div className="columns">
          <div className="column is-12 is-8-tablet">
            <h2 className="section-title">
              <FormattedMessage id="footer.title" />
            </h2>
          </div>
          <Responsive min="tablet">
            <div className="column is-2 is-3-tablet is-2-widescreen">
              {this._renderLogo()}
            </div>
          </Responsive>
        </div>
        <div className="columns footer-links">
          <div className="column is-12 is-4-tablet">
            <p className="footer-label">
              <FormattedMessage id="footer.contact" />
            </p>
            <a
              href="mailto:hello@cosmicode.pt"
              className="button footer-button"
            >
              hello@cosmicode.pt
            </a>
          </div>
          <div className="column is-12 is-4-tablet">
            <p className="footer-label">
              <FormattedMessage id="footer.project" />
            </p>
            <a
              href="mailto:hello@cosmicode.pt"
              className="button footer-button"
            >
              <FormattedMessage id="footer.quote" />
            </a>
          </div>
          <div className="column is-12 is-4-tablet">
            <p className="footer-label">
              <FormattedMessage id="footer.follow" />
            </p>
            <a
              href="https://www.facebook.com/cosmicode.studio"
              rel="noopener noreferrer"
              target="_blank"
            >
              <span className="footer-icon">
                <FacebookIcon />
              </span>
            </a>
            <a
              href="https://www.instagram.com/cosmicode"
              rel="noopener noreferrer"
              target="_blank"
            >
              <span className="footer-icon">
                <InstragramIcon />
              </span>
            </a>
            <a
              href="https://dribbble.com/cosmicode"
              rel="noopener noreferrer"
              target="_blank"
            >
              <span className="footer-icon">
                <DribbbleIcon />
              </span>
            </a>
          </div>
        </div>
        <p className="footer-copy">
          &copy;{" " + new Date().getFullYear() + " "}
          <FormattedMessage id="footer.copyright" />
        </p>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  locale: state.i18n.locale,
  logoFinished: state.app.animationFinished,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      animationFinished,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Footer);

import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import i18n from './i18n';
import app from './app';

const createRootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    i18n,
    app,
  });

export default createRootReducer;

import React, { PureComponent } from 'react';

export default class DribbbleIcon extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="22"
        height="22"
        viewBox="0 0 22 22"
      >
        <path
          fill="#FFF"
          d="M21.776 8.786A10.91 10.91 0 0 0 20.12 4.85a11.075 11.075 0 0 0-2.97-2.97A10.927 10.927 0 0 0 13.215.225a11.034 11.034 0 0 0-6.497.641A11.028 11.028 0 0 0 1.88 4.85 10.875 10.875 0 0 0 .222 8.786a11.091 11.091 0 0 0 0 4.43 11.01 11.01 0 0 0 1.658 3.936 11.05 11.05 0 0 0 4.838 3.983 11.021 11.021 0 0 0 6.497.64 10.878 10.878 0 0 0 3.935-1.656 11.005 11.005 0 0 0 4.626-6.903 11.045 11.045 0 0 0 0-4.43zm-1.384 2.12c-.136-.03-3.315-.714-6.543-.308-.067-.16-.134-.322-.203-.483-.197-.46-.406-.916-.625-1.364 3.71-1.514 5.216-3.692 5.234-3.718a9.363 9.363 0 0 1 2.137 5.873zM17.205 3.95c-.024.036-1.361 2.074-4.908 3.402a47.73 47.73 0 0 0-3.511-5.48 9.364 9.364 0 0 1 8.419 2.079zM8.75 1.88h-.001l-.029.007c.01-.002.02-.004.03-.008zm-1.758.624c.124.168 1.863 2.522 3.489 5.413-4.511 1.2-8.427 1.151-8.675 1.147a9.407 9.407 0 0 1 5.186-6.56zM1.605 11c0-.1.006-.198.008-.296.166.004 4.808.109 9.645-1.339.27.527.527 1.062.762 1.597a9.36 9.36 0 0 0-.37.112c-5.07 1.639-7.641 6.2-7.641 6.2s.004.003.004.005a9.358 9.358 0 0 1-2.408-6.279zm9.396 9.395a9.363 9.363 0 0 1-5.986-2.153c.124.1.211.165.211.165s1.8-3.927 7.372-5.869a.909.909 0 0 1 .064-.02c1.322 3.435 1.866 6.312 2.006 7.133a9.363 9.363 0 0 1-3.667.744zm5.249-1.603c-.096-.567-.596-3.324-1.83-6.709 3.034-.485 5.662.347 5.856.41a9.4 9.4 0 0 1-4.026 6.299z"
        />
      </svg>
    );
  }
}

import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import throttle from 'lodash/throttle';
import { Link } from 'react-router-dom';
import { scroller } from 'react-scroll';
import { FormattedMessage } from 'react-intl';
import Slider from 'react-slick';
import Lottie from 'react-lottie';
import ScrollVisible from '../../components/helpers/ScrollVisible';
import { Animation } from '../../components/animation';
import { Button, Image } from '../../components/elements';
import Project from './Project';
import {
  chocolateBox,
  chocolateBox2,
  chocolateBox3,
  guessup,
  guessup2,
  guessup3,
  london,
  london2,
  london3,
  myos,
  myos2,
  myos3,
  team,
  team2,
  team3,
  triangle,
} from '../../assets/img';
import { home } from '../../assets/animations';
import { changeMenuColor } from '../../redux/actions/app';
import Footer from '../../components/navigation/Footer';
import Responsive from '../../components/helpers/Responsive';

class Home extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      animated: false,
      weDoSelected: 0,
    };

    this.servicesArray = [
      [
        'home.services.ux_consultancy',
        'home.services.user_research',
        'home.services.user_testing',
        'home.services.analytics',
        'home.services.monetisation',
        'home.services.gamification',
      ],
      [
        'home.services.wireframing',
        'home.services.user_experience',
        'home.services.web_app_design',
        'home.services.illustration',
        'home.services.motion_design',
        'home.services.branding',
        'home.services.copywriting',
      ],
      [
        'home.services.ios',
        'home.services.android',
        'home.services.web',
        'home.services.vr',
        'home.services.hosting',
        'home.services.maintenance',
      ],
    ];

    this._handleScroll = throttle(this._handleScroll.bind(this), 200);
  }

  componentDidMount() {
    if (window && window.addEventListener) {
      window.addEventListener('scroll', this._handleScroll);
      this._handleScroll();
    }

    setTimeout(() => {
      window.scrollTo(0, 0);
      this.setState({
        animated: true,
      });
    }, 300);
  }

  componentWillUnmount() {
    if (window && window.removeEventListener) {
      window.removeEventListener('scroll', this._handleScroll);
    }
  }

  _handleScroll() {
    let shouldBeWhite = true;
    if (this.projects) {
      let position = this.projects.getBoundingClientRect().top - 80;

      if (position < 0) {
        shouldBeWhite = false;
      }
    }
    if (this.what) {
      let position = this.what.getBoundingClientRect().top - 80;

      if (position < 0) {
        shouldBeWhite = true;
      }
    }
    if (this.team) {
      let position = this.team.getBoundingClientRect().top - 80;

      if (position < 0) {
        shouldBeWhite = false;
      }
    }
    if (this.footer) {
      let position = this.footer.getBoundingClientRect().top - 80;

      if (position < 0) {
        shouldBeWhite = true;
      }
    }

    this.props.changeMenuColor(shouldBeWhite);
  }

  _renderMainSection() {
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: home,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
    };

    return (
      <div className="main-section" style={{ position: 'relative' }}>
        <Responsive min="tablet">
          <div
            style={{
              position: 'absolute',
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
            }}
          >
            <Lottie options={defaultOptions} width="100%" height="100%" />
          </div>
        </Responsive>
        <Responsive max="landscape">
          <Animation
            distance="80%"
            delay={300}
            animation="fadeRight"
            className="home-triangle"
          >
            <Image src={triangle} />
          </Animation>
        </Responsive>
        <div className="container">
          <div className="columns is-full-height-desktop">
            <div
              className="column display-flex justify-content-center flex-direction-column"
              style={{ flex: 1 }}
            >
              <Animation delay={300}>
                <h1 className="page-title">
                  <Responsive max="landscape">
                    <FormattedMessage id="home.title_mobile" />
                  </Responsive>
                  <Responsive min="tablet">
                    <FormattedMessage id="home.title" />
                  </Responsive>
                </h1>
              </Animation>
              <Animation delay={1000}>
                <Button
                  onClick={() => {
                    scroller.scrollTo('projects', {
                      duration: 500,
                      delay: 0,
                      smooth: 'easeInOut',
                    });
                  }}
                >
                  <FormattedMessage id="home.featured" />
                </Button>
              </Animation>
            </div>
          </div>
        </div>
      </div>
    );
  }

  _renderWhatWeDoSlider() {
    let settings = {
      speed: 300,
      slidesToShow: 1.2,
      slidesToScroll: 1,
      swipeToSlide: true,
      focusOnSelect: true,
      infinite: false,
      dots: true,
      arrows: false,
      customPaging: function (i) {
        return (
          <div className="slider-dot">
            {i}
            <div className="slider-color" />
          </div>
        );
      },
    };

    return (
      <div className="container">
        <Slider className="how-mobile-services-wrapper" {...settings}>
          <div className="how-mobile-services">
            <h2>
              <FormattedMessage id="home.services.strategy" />
            </h2>
            <ul className="service-secondary-list">
              {this.servicesArray[0].map((value, key) => {
                let number = key + 1 < 10 ? '0' + (key + 1) : key + 1;
                return (
                  <li key={key}>
                    <span>{number}</span>
                    <FormattedMessage id={value} tagName="p" />
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="how-mobile-services">
            <h2>
              <FormattedMessage id="home.services.design" />
            </h2>
            <ul className="service-secondary-list">
              {this.servicesArray[1].map((value, key) => {
                let number = key + 1 < 10 ? '0' + (key + 1) : key + 1;
                return (
                  <li key={key}>
                    <span>{number}</span>
                    <FormattedMessage id={value} tagName="p" />
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="how-mobile-services">
            <h2>
              <FormattedMessage id="home.services.development" />
            </h2>
            <ul className="service-secondary-list">
              {this.servicesArray[2].map((value, key) => {
                let number = key + 1 < 10 ? '0' + (key + 1) : key + 1;
                return (
                  <li key={key}>
                    <span>{number}</span>
                    <FormattedMessage id={value} tagName="p" />
                  </li>
                );
              })}
            </ul>
          </div>
        </Slider>
      </div>
    );
  }

  _renderWhatWeDo() {
    return (
      <div className="columns what-we-do-services">
        <Responsive min="tablet">
          <div className="column is-12 is-5-tablet is-3-desktop is-offset-5-desktop">
            <ul className="service-list">
              <li
                className={this.state.weDoSelected === 0 ? 'is-active' : null}
                onMouseEnter={() => {
                  this.setState({
                    weDoSelected: 0,
                  });
                }}
              >
                <FormattedMessage id="home.services.strategy" />
              </li>
              <li
                className={this.state.weDoSelected === 1 ? 'is-active' : null}
                onMouseEnter={() => {
                  this.setState({
                    weDoSelected: 1,
                  });
                }}
              >
                <FormattedMessage id="home.services.design" />
              </li>
              <li
                className={this.state.weDoSelected === 2 ? 'is-active' : null}
                onMouseEnter={() => {
                  this.setState({
                    weDoSelected: 2,
                  });
                }}
              >
                <FormattedMessage id="home.services.development" />
              </li>
            </ul>
          </div>
          <div className="column">
            <div className="service-secondary-list-wrapper">
              <ul
                className="service-secondary-list"
                style={{ opacity: this.state.weDoSelected === 0 ? 1 : 0 }}
              >
                {this.servicesArray[0].map((value, key) => {
                  let number = key + 1 < 10 ? '0' + (key + 1) : key + 1;
                  return (
                    <li key={key}>
                      <span>{number}</span>
                      <FormattedMessage id={value} tagName="p" />
                    </li>
                  );
                })}
              </ul>
              <ul
                className="service-secondary-list"
                style={{ opacity: this.state.weDoSelected === 1 ? 1 : 0 }}
              >
                {this.servicesArray[1].map((value, key) => {
                  let number = key + 1 < 10 ? '0' + (key + 1) : key + 1;
                  return (
                    <li key={key}>
                      <span>{number}</span>
                      <FormattedMessage id={value} tagName="p" />
                    </li>
                  );
                })}
              </ul>
              <ul
                className="service-secondary-list"
                style={{ opacity: this.state.weDoSelected === 2 ? 1 : 0 }}
              >
                {this.servicesArray[2].map((value, key) => {
                  let number = key + 1 < 10 ? '0' + (key + 1) : key + 1;
                  return (
                    <li style={{ height: '28px' }} key={key}>
                      <span>{number}</span>
                      <FormattedMessage id={value} tagName="p" />
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </Responsive>
      </div>
    );
  }

  render() {
    const { locale } = this.props;

    return (
      <div
        className="main-wrapper"
        style={{
          transition: 'opacity 300ms',
          opacity: this.state.animated ? 1 : 0,
        }}
      >
        {this._renderMainSection()}
        <div
          id="projects"
          ref={(projects) => {
            this.projects = projects;
          }}
          className="projects"
        >
          <ScrollVisible>
            {(visible) => (
              <Project
                key="london"
                animate={visible}
                title="london.title"
                class="london"
                description="london.description"
                services="london.services"
                image={london}
                imageSet={london2 + ' 2x, ' + london3 + ' 3x'}
                link="https://futures.centreforlondon.org/"
              />
            )}
          </ScrollVisible>
          <ScrollVisible>
            {(visible) => (
              <Project
                key="myos"
                animate={visible}
                class="myos"
                title="myos.title"
                description="myos.description"
                services="myos.services"
                image={myos}
                imageSet={myos2 + ' 2x, ' + myos3 + ' 3x'}
                link="https://myos.eu/"
              />
            )}
          </ScrollVisible>
          <ScrollVisible>
            {(visible) => (
              <Project
                key="guessup"
                animate={visible}
                title="guessup.title"
                class="guessup"
                description="guessup.description"
                services="guessup.services"
                image={guessup}
                imageSet={guessup2 + ' 2x, ' + guessup3 + ' 3x'}
                link="https://cosmicode.games/guessup"
              />
            )}
          </ScrollVisible>
          <ScrollVisible>
            {(visible) => (
              <Project
                key="chocolatebox"
                animate={visible}
                title="chocolatebox.title"
                class="chocolatebox"
                description="chocolatebox.description"
                services="chocolatebox.services"
                image={chocolateBox}
                imageSet={chocolateBox2 + ' 2x, ' + chocolateBox3 + ' 3x'}
                link="https://chocolatebox.pt"
              />
            )}
          </ScrollVisible>
        </div>
        <div
          ref={(what) => {
            this.what = what;
          }}
          className="what-we-do-section"
        >
          <div className="container">
            <div className="columns">
              <div className="column is-12 is-5-tablet">
                <h2 className="section-title">
                  <FormattedMessage id="home.what_we_do.title" />
                </h2>
              </div>
              <div className="column is-12 is-7-tablet is-6-widescreen">
                <p className="section-text">
                  <FormattedMessage id="home.what_we_do.description" />
                </p>
              </div>
            </div>

            {this._renderWhatWeDo()}
          </div>
          <Responsive max="landscape">
            {this._renderWhatWeDoSlider()}
          </Responsive>
          <Responsive min="desktop">
            <div className="donut-square" />
          </Responsive>
        </div>
        <div
          ref={(team) => {
            this.team = team;
          }}
          className="part-team-section"
        >
          <div className="container">
            <div className="columns">
              <div className="column is-12 is-5-tablet">
                <h2 className="section-title">
                  <FormattedMessage id="home.studio.title" />
                </h2>
              </div>
              <div className="column is-12 is-7-tablet is-6-widescreen">
                <p className="section-text">
                  <FormattedMessage id="home.studio.description" />
                </p>
              </div>
            </div>
            <div className="columns">
              <div className="column is-12 is-5-tablet">
                <Responsive min="tablet">
                  <Link
                    to={'/' + locale + '/studio'}
                    className="button is-black"
                  >
                    <FormattedMessage id="home.studio.button" />
                  </Link>
                </Responsive>
              </div>
              <div className="column is-12 is-7-tablet is-6-widescreen">
                <Image src={team} srcSet={team2 + ' 2x, ' + team3 + ' 3x'} />
                <Responsive max="landscape">
                  <Link
                    to={'/' + locale + '/studio'}
                    className="button is-black"
                  >
                    <FormattedMessage id="home.studio.button" />
                  </Link>
                </Responsive>
              </div>
            </div>
            <Responsive min="desktop">
              <ScrollVisible>
                {(visible) => {
                  return (
                    <div
                      className="square"
                      style={{ width: !visible ? '0%' : '80%' }}
                    />
                  );
                }}
              </ScrollVisible>
            </Responsive>
          </div>
        </div>
        <div
          ref={(footer) => {
            this.footer = footer;
          }}
          className="footer"
        >
          <ScrollVisible>
            {(visible) => {
              return <Footer visible={visible} />;
            }}
          </ScrollVisible>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  locale: state.i18n.locale,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      changeMenuColor,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);

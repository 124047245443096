import React, { Component } from 'react';
import propTypes from 'prop-types';
import throttle from 'lodash/throttle';

class ScrollVisible extends Component {
  static posTop() {
    if (typeof window.pageYOffset !== 'undefined') {
      return window.pageYOffset;
    } else if (document.documentElement.scrollTop) {
      return document.documentElement.scrollTop;
    } else if (document.body.scrollTop) {
      return document.body.scrollTop;
    }
    return 0;
  }

  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      elementTop: null
    };

    this._handleResize = throttle(this._handleResize.bind(this), 200);
    this._handleScroll = throttle(this._handleScroll.bind(this), 200);
  }

  componentDidMount() {
    if (window && window.addEventListener) {
      window.addEventListener('scroll', this._handleScroll);
      window.addEventListener('resize', this._handleResize);
    }

    setTimeout(() => {
      this._handleResize();
    }, 0);
  }

  componentWillUnmount() {
    if (window && window.removeEventListener) {
      window.removeEventListener('scroll', this._handleScroll);
      window.removeEventListener('resize', this._handleResize);
    }
  }

  _handleResize() {
    if (this.group) {
      this.setState(
        {
          elementTop:
            this.group.getBoundingClientRect().top + ScrollVisible.posTop()
        },
        this._handleScroll
      );
    }
  }

  _handleScroll() {
    let visible = this._isVisible();

    if (!this.state.elementTop) {
      return;
    }

    if (visible !== this.state.visible) {
      if (this.state.visible === true && this.props.executeOnce) return;

      this.setState({
        visible
      });

      if (visible) {
        if (this.props.onEnter) this.props.onEnter();
      } else {
        if (this.props.onLeave) this.props.onLeave();
      }
    }
  }

  _isVisible() {
    let offset = this.props.offset;
    const viewTop = window.pageYOffset;

    return this.state.elementTop + offset <= viewTop + window.innerHeight;
  }

  render() {
    return (
      <div
        ref={group => {
          this.group = group;
        }}
      >
        {this.props.children(this.state.visible)}
      </div>
    );
  }
}

ScrollVisible.propTypes = {
  offset: propTypes.number,
  onEnter: propTypes.func,
  onLeave: propTypes.func,
  executeOnce: propTypes.bool
};

ScrollVisible.defaultProps = {
  offset: 100,
  executeOnce: true
};

export default ScrollVisible;

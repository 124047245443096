const initialState = {
    region: null,
    locale: null
};

export default function i18n(state = initialState, action) {
    switch (action.type) {
        case 'CHANGE_LOCALE':
            return {
                ...state,
                locale: action.payload
            };
        case 'CHANGE_REGION':
            return {
                ...state,
                region: action.payload
            };
        default:
            return state;
    }
}
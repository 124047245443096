import React, { PureComponent } from "react";
import { bindActionCreators } from "redux";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import throttle from "lodash/throttle";
import { connect } from "react-redux";
import { Animation } from "../../components/animation";
import { Image } from "../../components/elements";
import { contacts, contacts2, contacts3 } from "../../assets/img";
import ScrollVisible from "../../components/helpers/ScrollVisible";
import { changeMenuColor } from "../../redux/actions/app";
import Map from "./Map";
import Footer from "../../components/navigation/Footer";

class Contacts extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      animated: false,
      showMap: false,
    };

    this._handleScroll = throttle(this._handleScroll.bind(this), 200);
  }

  componentDidMount() {
    if (window && window.addEventListener) {
      window.addEventListener("scroll", this._handleScroll);
      this._handleScroll();
    }

    setTimeout(() => {
      window.scrollTo(0, 0);
      this.setState({
        animated: true,
      });
    }, 300);

    setTimeout(() => {
      this.setState({
        showMap: true,
      });
    }, 600);
  }

  componentWillUnmount() {
    if (window && window.removeEventListener) {
      window.removeEventListener("scroll", this._handleScroll);
    }
  }

  _handleScroll() {
    let shouldBeWhite = true;
    if (this.projects) {
      let position = this.projects.getBoundingClientRect().top - 80;

      if (position < 0) {
        shouldBeWhite = false;
      }
    }
    if (this.contacts) {
      let position = this.contacts.getBoundingClientRect().top - 80;

      if (position < 0) {
        shouldBeWhite = false;
      }
    }
    if (this.footer) {
      let position = this.footer.getBoundingClientRect().top - 80;

      if (position < 0) {
        shouldBeWhite = true;
      }
    }

    this.props.changeMenuColor(shouldBeWhite);
  }

  _renderMap() {
    if (this.state.showMap)
      return (
        <Animation
          className="map-container"
          animation="slideUp"
          delay={0}
          distance="20%"
        >
          <div className="container">
            <Map
              loadingElement={<div style={{ height: "100%" }} />}
              containerElement={<div className="map" />}
              mapElement={<div style={{ height: "100%" }} />}
            />
          </div>
        </Animation>
      );
  }

  _renderMainSection() {
    const { locale } = this.props;

    return (
      <div className="contacts-main-section" style={{ position: "relative" }}>
        <div className="container">
          <div className="columns is-full-height-desktop">
            <div
              className="column display-flex justify-content-center flex-direction-column"
              style={{ flex: 1 }}
            >
              <Animation delay={300}>
                <h1 className="page-title is-text-center">
                  <FormattedMessage id="contacts.title" />
                </h1>
              </Animation>
              <Animation delay={600} style={{ textAlign: "center" }}>
                <a href="mailto:hello@cosmicode.pt" className="button">
                  <FormattedMessage id="contacts.quote" />
                </a>
              </Animation>
            </div>
          </div>
        </div>
        {this._renderMap()}
      </div>
    );
  }

  render() {
    return (
      <div
        className="main-wrapper"
        style={{
          transition: "opacity 300ms",
          opacity: this.state.animated ? 1 : 0,
        }}
      >
        {this._renderMainSection()}
        <div className="black-contacts" />
        <ScrollVisible offset={300}>
          {(visible) => (
            <div
              ref={(contacts) => {
                this.contacts = contacts;
              }}
              className="contacts-section"
            >
              <Animation animate={visible} className="container">
                <div className="columns">
                  <div className="column is-12 is-5-tablet">
                    <h2 className="section-title">
                      <FormattedMessage id="contacts.body" />
                    </h2>
                    <p className="section-text">
                      Rua Cândido dos Reis 137
                      <br />
                      4400-073 Vila Nova de Gaia
                      <br />
                      Portugal
                    </p>
                  </div>
                  <div className="column is-offset-1-tablet">
                    <Image
                      src={contacts}
                      srcSet={contacts2 + " 2x, " + contacts3 + " 3x"}
                    />
                  </div>
                </div>
              </Animation>
            </div>
          )}
        </ScrollVisible>
        <div
          ref={(footer) => {
            this.footer = footer;
          }}
          className="footer"
        >
          <ScrollVisible>
            {(visible) => <Footer visible={visible} />}
          </ScrollVisible>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  locale: state.i18n.locale,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      changeMenuColor,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Contacts);

import React, { PureComponent } from 'react';
import { spring, TransitionMotion } from 'react-motion';

const SPEED = 2000;
const ADJUSTING_SPEED = 150;
const DEFAULT_DIMENSION = 'auto';

class CountryLoop extends PureComponent {
  constructor(props) {
    super(props);

    this.wordBox = null;
    this.springConfig = { stiffness: 340, damping: 30 };
    this.countries = [
      'Olá, Portugal',
      'Salut, France',
      'Hallo, Deutschland',
      'Hello, UK',
      'Hola, Mexico',
      'Oi, Brasil',
      'Ciao, Italia',
      'Cześć, Polska',
      'Hej, Sverige',
      'Hello, United States',
      'Hola, España'
    ];

    let rand = Math.ceil(Math.random() * (this.countries.length - 1));

    this.state = {
      currentWord: rand,
      wordCount: 0,
      hasLoaded: false
    };
  }

  componentDidMount() {
    this.tick();
    this.tickInterval = setInterval(this.tick, SPEED);
  }

  componentWillUnmount() {
    if (this.tickInterval !== null) {
      clearInterval(this.tickInterval);
    }
  }

  // Fade out animation
  willLeave = () => {
    const { height } = this.getDimensions();

    return {
      opacity: spring(this.getOpacity(), this.springConfig),
      translate: spring(-height, this.springConfig)
    };
  };

  // Fade in animation
  willEnter = () => {
    const { height } = this.getDimensions();

    return {
      opacity: this.getOpacity(),
      translate: height
    };
  };

  tick = () => {
    this.setState(state => {
      if (!state.hasLoaded) {
        return {
          hasLoaded: true
        };
      }

      return {
        currentWord: (state.currentWord + 1) % this.countries.length,
        wordCount: (state.wordCount + 1) % 1000 // just a safe value to avoid infinite counts,
      };
    });
  };

  getOpacity() {
    return 0;
  }

  getDimensions() {
    if (this.wordBox === null) {
      return {
        width: DEFAULT_DIMENSION,
        height: DEFAULT_DIMENSION
      };
    }

    return this.wordBox.getBoundingClientRect();
  }

  getStyles() {
    const { height } = this.getDimensions();

    return {
      display: 'inline-block',
      position: 'relative',
      verticalAlign: 'top',
      height: height
    };
  }

  getTextStyles(isStatic) {
    const position = isStatic ? 'relative' : 'absolute';
    return {
      whiteSpace: 'nowrap',
      display: 'inline-block',
      left: 0,
      top: 0,
      fontWeight: 500,
      position,
      marginTop: 20
    };
  }

  getTransitionMotionStyles() {
    const { wordCount, currentWord } = this.state;

    return [
      {
        key: `step-${wordCount}`,
        data: {
          text: this.countries[currentWord]
        },
        style: {
          opacity: spring(1, this.springConfig),
          translate: spring(0, this.springConfig)
        }
      }
    ];
  }

  renderStatic() {
    return (
      <p
        className="section-text"
        ref={n => {
          this.wordBox = n;
        }}
      >
        {this.countries[this.state.currentWord]}
      </p>
    );
  }

  renderAnimation() {
    return (
      <TransitionMotion
        willLeave={this.willLeave}
        willEnter={this.willEnter}
        styles={this.getTransitionMotionStyles()}
      >
        {interpolatedStyles => {
          const { width, height } = this.getDimensions();
          return (
            <div
              style={{
                transition: `width ${ADJUSTING_SPEED}ms linear`,
                height,
                width
              }}
            >
              {interpolatedStyles.map(config => (
                <p
                  ref={n => {
                    this.wordBox = n;
                  }}
                  className="section-text"
                  key={config.key}
                  style={{
                    ...this.getTextStyles(width === DEFAULT_DIMENSION),
                    opacity: config.style.opacity,
                    transform: `translateY(${config.style.translate}px)`
                  }}
                >
                  {config.data.text}
                </p>
              ))}
            </div>
          );
        }}
      </TransitionMotion>
    );
  }

  render() {
    return (
      <div style={{ ...this.getStyles() }}>
        {!this.state.hasLoaded ? this.renderStatic() : this.renderAnimation()}
      </div>
    );
  }
}

export default CountryLoop;

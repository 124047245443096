import React, { Component } from 'react';
import propTypes from 'prop-types';
import classnames from 'classnames';
import Animation from '../animation/Animation';

const DURATION = 300;

class Modal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      animated: this.props.open,
      visible: this.props.open
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.open !== this.props.open) {
      if (!this.props.open) {
        this.setState(
          {
            animated: false
          },
          () => {
            setTimeout(() => {
              this.setState({
                visible: false
              });
            }, DURATION);
          }
        );
      } else {
        this.setState(
          {
            visible: true
          },
          () => {
            setTimeout(() => {
              this.setState({
                animated: true
              });
            }, 100);
          }
        );
      }
    }
  }

  render() {
    let classes = classnames(
      {
        modal: true,
        'is-open': this.state.visible
      },
      this.props.className
    );

    return (
      <div style={this.props.style} className={classes}>
        <Animation
          className="modal-background"
          animation="fade"
          duration={DURATION}
          animate={this.state.animated}
          onClick={this.props.onClick}
        />
        <Animation
          delay={DURATION}
          duration={DURATION}
          animate={this.state.animated}
          className="modal-content"
          onClick={e => {
            e.stopPropagation();
          }}
        >
          {this.props.children}
        </Animation>
      </div>
    );
  }
}

Modal.propTypes = {
  open: propTypes.bool,
  onClick: propTypes.func
};

Modal.defaultProps = {
  open: false
};

export default Modal;

import React, { PureComponent } from 'react';
import ClassNames from 'classnames';
import propTypes from 'prop-types';

class Image extends PureComponent {
  render() {
    let classes = ClassNames(
      {
        image: true
      },
      this.props.className
    );

    return (
      <div
        className={classes}
        style={this.props.style}
        onClick={this.props.onClick}
      >
        <img
          src={this.props.src}
          sizes={this.props.sizes}
          srcSet={this.props.srcSet}
          alt={this.props.alt}
          style={this.props.imageStyle}
        />
      </div>
    );
  }
}

Image.propTypes = {
  src: propTypes.string,
  alt: propTypes.string
};

Image.defaultProps = {};

export default Image;

import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

class NotFound extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      date: new Date()
    };
  }

  render() {
    const { locale } = this.props;

    return (
      <section className="error-section">
        <div className="container">
          <div className="columns">
            <div className="column is-12 is-5-tablet">
              <h2 className="section-title">Ooops!</h2>
            </div>
            <div className="column is-12 is-7-tablet is-6-widescreen">
              <p className="section-text">
                The page you're looking for cannot be found.
              </p>
              <Link to={'/' + locale} className="button project-button">
                Go back
              </Link>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = state => ({
  locale: state.i18n.locale
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators({}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(NotFound);

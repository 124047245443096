import React, { PureComponent } from "react";
import { FormattedMessage } from "react-intl";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import classnames from "classnames";
import { connect } from "react-redux";
import Responsive from "../helpers/Responsive";
import { toggleMenu } from "../../redux/actions/app";

class Header extends PureComponent {
  _renderStudioLink() {
    const { locale } = this.props;

    let classes = classnames({
      "header-link": true,
      "is-active": this.props.location.pathname === "/" + locale + "/studio",
    });

    return (
      <Link to={"/" + locale + "/studio"} className={classes}>
        <FormattedMessage id="header.studio" />
      </Link>
    );
  }

  _renderContactsLink() {
    const { locale } = this.props;

    let classes = classnames({
      "header-link": true,
      "is-active": this.props.location.pathname === "/" + locale + "/contacts",
    });

    return (
      <Link to={"/" + locale + "/contacts"} className={classes}>
        <FormattedMessage id="header.contacts" />
      </Link>
    );
  }

  _renderLocaleLink() {
    const { locale, location } = this.props;

    let classes = classnames({
      "header-link": true,
    });

    let newLocale = locale === "pt" ? "en" : "pt";

    let url = location.pathname.replace("/" + locale, "/" + newLocale);

    return (
      <a href={url} className={classes}>
        {newLocale.toUpperCase()}
      </a>
    );
  }

  render() {
    const { locale } = this.props;

    let classes = classnames({
      header: true,
      "is-fixed": true,
      "is-black": this.props.menuColor === "black" && !this.props.menuOpened,
    });

    let burgerClasses = classnames({
      "header-burger": true,
      "menu-opened": this.props.menuOpened,
    });

    return (
      <div className={classes}>
        <div className="container">
          <div className="header-left">
            <Link
              to={"/" + locale}
              onClick={() => {
                this.props.toggleMenu(false);
              }}
              className="header-title header-link"
            >
              COSMICODE
            </Link>
          </div>
          <Responsive min="tablet">
            <div className="header-right">
              {this._renderStudioLink()}
              {this._renderContactsLink()}
              {this._renderLocaleLink()}
            </div>
          </Responsive>
          <Responsive max="landscape">
            <div className="header-right">
              <div
                onClick={() => {
                  this.props.toggleMenu(!this.props.menuOpened);
                }}
                className={burgerClasses}
              >
                <span />
                <span />
              </div>
            </div>
          </Responsive>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  locale: state.i18n.locale,
  menuColor: state.app.menuColor,
  menuOpened: state.app.menuOpened,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      toggleMenu,
    },
    dispatch
  );
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));

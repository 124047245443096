import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { IntlProvider } from 'react-intl';
import { withRouter } from 'react-router';
import { changeLocale, changeRegion } from '../../redux/actions/locale';
import Helmet from 'react-helmet';
// Locales
import messages, { appLocales, defaultLanguage } from '../../lang';

class App extends Component {
  componentDidMount() {
    let lang = this.props.match.params.lang || defaultLanguage;

    let userLocale = navigator.language || navigator.userLanguage || 'en';
    let userLocaleArray = userLocale.toLowerCase().split(/[_-]+/);
    if (userLocaleArray.length > 1) {
      this.props.changeRegion(userLocaleArray[1]);
    }

    if (appLocales.length > 1 && appLocales.indexOf(lang) === -1) {
      this.props.changeLocale(defaultLanguage);
      this.props.history.push(defaultLanguage + '/' + lang);
    } else {
      this.props.changeLocale(lang);
    }
  }

  render() {
    if (!this.props.locale) {
      return null;
    }

    let region = this.props.region ? '-' + this.props.region : '';
    let locale = this.props.locale + region;

    let title = 'Cosmicode: A Digital Product Studio';
    let description =
      'We are a digital product studio that loves to help businesses grow. We are focused in design and development of mobile apps and websites.';

    return (
      <div>
        <Helmet
          title={title}
          meta={[{ name: 'description', content: description }]}
        >
          <html lang={this.props.locale} />
        </Helmet>

        <IntlProvider locale={locale} messages={messages[this.props.locale]}>
          {this.props.children}
        </IntlProvider>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  locale: state.i18n.locale,
  region: state.i18n.region,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      changeLocale,
      changeRegion,
    },
    dispatch
  );
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));

export function toggleMenu(open) {
  return {
    type: 'TOGGLE_MENU',
    payload: open
  };
}

export function changeMenuColor(white = true) {
  return {
    type: 'CHANGE_MENU_COLOR',
    payload: white ? 'white' : 'black'
  };
}

export function animationFinished() {
  return {
    type: 'ANIMATION_FINISHED'
  };
}

export const sendQuote = (
  name,
  email,
  company,
  phone,
  description,
  needsMobile,
  needsWeb,
  needsDesign,
  other,
  budget,
  launch,
  discover
) => {
  return {
    type: 'SEND_QUOTE',
    payload: {
      name,
      email,
      company,
      phone,
      description,
      needsMobile,
      needsWeb,
      needsDesign,
      other,
      budget,
      launch,
      discover
    }
  };
};

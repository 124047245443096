import React, { PureComponent } from 'react';
import propTypes from 'prop-types';
import throttle from 'lodash/throttle';

class Responsive extends PureComponent {
  constructor(props) {
    super(props);

    this.breakpoints = {
      landscape: 576,
      tablet: 768,
      desktop: 992,
      widescreen: 1200,
      fullhd: 1600
    };

    this.state = {
      matches: false
    };

    this._handleResize = throttle(this._handleResize.bind(this), 200);
  }

  componentDidMount() {
    if (window && window.addEventListener) {
      window.addEventListener('resize', this._handleResize);
      window.addEventListener('orientationchange', this._handleResize);
    }

    this._handleResize();
  }

  componentWillUnmount() {
    if (window && window.removeEventListener) {
      window.removeEventListener('resize', this._handleResize);
      window.removeEventListener('orientationchange', this._handleResize);
    }
  }

  _handleResize() {
    let matches = this._checkWidth();

    if (matches !== this.state.matches) {
      this.setState({
        matches
      });
    }
  }

  _checkWidth() {
    let width = window.innerWidth;
    let height = window.innerHeight;
    let minWidth = 0;
    let maxWidth = 99999;

    if (this.props.min !== 'all') {
      minWidth = this.breakpoints[this.props.min];
    }
    if (this.props.max !== 'all' && this.props.max !== 'fullhd') {
      switch (this.props.max) {
        case 'mobile':
          maxWidth = this.breakpoints['landscape'] - 1;
          break;
        case 'landscape':
          maxWidth = this.breakpoints['tablet'] - 1;
          break;
        case 'tablet':
          maxWidth = this.breakpoints['desktop'] - 1;
          break;
        case 'desktop':
          maxWidth = this.breakpoints['widescreen'] - 1;
          break;
        case 'widescren':
          maxWidth = this.breakpoints['fullhd'] - 1;
          break;
      }
    }

    if (width < minWidth) {
      return false;
    } else if (width > maxWidth) {
      return false;
    }
    if (this.props.orientation !== 'all') {
      if (this.props.orientation === 'portrait') {
        return height >= width;
      } else {
        return width >= height;
      }
    }

    return true;
  }

  render() {
    if (!this.state.matches) {
      return null;
    }

    return this.props.children;
  }
}

Responsive.propTypes = {
  min: propTypes.oneOf([
    'all',
    'landscape',
    'tablet',
    'desktop',
    'widescreen',
    'fullhd'
  ]),
  max: propTypes.oneOf([
    'all',
    'landscape',
    'tablet',
    'desktop',
    'widescreen',
    'fullhd'
  ]),
  orientation: propTypes.oneOf(['all', 'landscape', 'orientation'])
};

Responsive.defaultProps = {
  breakpoint: 'all',
  orientation: 'all'
};

export default Responsive;

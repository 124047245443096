const initialState = {
  menuOpened: false,
  menuColor: 'white',
  isSendingQuote: false,
  errorQuote: '',
  animationFinished: false
};

export default function i18n(state = initialState, action) {
  switch (action.type) {
    case 'TOGGLE_MENU':
      return {
        ...state,
        menuOpened: action.payload
      };
    case 'CHANGE_MENU_COLOR':
      return {
        ...state,
        menuColor: action.payload
      };
    case 'SEND_QUOTE':
      return {
        ...state,
        isSendingQuote: true
      };
    case 'QUOTE_SUCCESS':
      return {
        ...state,
        isSendingQuote: false,
        errorQuote: ''
      };
    case 'QUOTE_FAIL':
      return {
        ...state,
        isSendingQuote: false,
        errorQuote: action.payload.error
      };
    case 'ANIMATION_FINISHED':
      return {
        ...state,
        animationFinished: true
      };
    default:
      return state;
  }
}

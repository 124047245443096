import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import Slider from 'react-slick';
import throttle from 'lodash/throttle';
import ScrollVisible from '../../components/helpers/ScrollVisible';
import Lottie from 'react-lottie';
import mapAnim from '../../assets/animations/mapAnim.json';
import howWeDo from '../../assets/animations/howWeDo.json';
import { Animation } from '../../components/animation';
import { Image } from '../../components/elements';
import CountryLoop from './CountryLoop';
import { changeMenuColor } from '../../redux/actions/app';
import {
  map,
  mapMobile,
  studio,
  studio1_1,
  studio1_2,
  studio1_3,
  studio1Mobile,
  studio1Mobile2,
  studio1Mobile3,
  studio2,
  studio2_1,
  studio2_2,
  studio2_3,
  studio2Mobile,
  studio2Mobile2,
  studio2Mobile3,
  studio3,
  studio3_1,
  studio3_2,
  studio3_3,
  studio3Mobile,
  studio3Mobile2,
  studio3Mobile3,
  studio4_1,
  studio4_2,
  studio4_3,
  studio4Mobile,
  studio4Mobile2,
  studio4Mobile3,
  studio5_1,
  studio5_2,
  studio5_3,
  studio5Mobile,
  studio5Mobile2,
  studio5Mobile3,
  studioMobile,
  studioMobile2,
  studioMobile3
} from '../../assets/img';
import Footer from '../../components/navigation/Footer';
import Responsive from '../../components/helpers/Responsive';

class Studio extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      animated: false,
      howDoSelected: 0,
      animationRunning: false,
      animationReverse: false,
      animationSpeed: 1,
      mapWidth: '100%',
      mapHeight: '100%'
    };

    this.frame = 0;
    this.frameTotal = 389;
    this.frameSpeedMultiplier = 10;
    this.frameBreakpoints = [0, 40, 80, 120, [180, 356]];
    this._handleScroll = throttle(this._handleScroll.bind(this), 200);

    this._renderHowSlider = this._renderHowSlider.bind(this);
    this._getMapSize = this._getMapSize.bind(this);
  }

  componentDidMount() {
    if (window && window.addEventListener) {
      window.addEventListener('scroll', this._handleScroll);
      window.addEventListener('resize', this._getMapSize);
      window.addEventListener('orientationchange', this._getMapSize);
    }
    this._handleScroll();
    this._getMapSize();

    setTimeout(() => {
      window.scrollTo(0, 0);
      this.setState({
        animated: true
      });
    }, 300);
  }

  componentWillUnmount() {
    if (window && window.removeEventListener) {
      window.removeEventListener('scroll', this._handleScroll);
      window.removeEventListener('resize', this._getMapSize);
      window.removeEventListener('orientationchange', this._getMapSize);
    }

    this.setState({
      animationRunning: false
    });
  }

  _handleScroll() {
    let offset = 80;
    if (window.innerWidth < 768) {
      offset = 45;
    }

    let shouldBeWhite = true;
    if (this.projects) {
      let position = this.projects.getBoundingClientRect().top - offset;

      if (position < 0) {
        shouldBeWhite = false;
      }
    }
    if (this.culture) {
      let position = this.culture.getBoundingClientRect().top - offset;

      if (position < 0) {
        shouldBeWhite = false;
      }
    }
    if (this.how) {
      let position = this.how.getBoundingClientRect().top - offset;

      if (position < 0) {
        shouldBeWhite = true;
      }
    }
    if (this.globally) {
      let position = this.globally.getBoundingClientRect().top - offset;

      if (position < 0) {
        shouldBeWhite = false;
      }
    }
    if (this.footer) {
      let position = this.footer.getBoundingClientRect().top - offset;

      if (position < 0) {
        shouldBeWhite = true;
      }
    }

    this.props.changeMenuColor(shouldBeWhite);
  }

  _getMapSize() {
    let width = '100%';
    let height = '100%';

    if (window && window.innerWidth) {
      if (window.innerWidth >= 1366) {
        width = Math.min(1280, window.innerWidth);
        height = width * 1490 / 3099;
      } else if (window.innerWidth >= 1200) {
        width = Math.min(1140, window.innerWidth);
        height = width * 1490 / 3099;
      } else if (window.innerWidth >= 992) {
        width = Math.min(960, window.innerWidth);
        height = width * 1490 / 3099;
      } else if (window.innerWidth >= 768) {
        width = Math.min(720, window.innerWidth);
        height = width * 1490 / 3099;
      }
    }

    this.setState({
      mapWidth: width,
      mapHeight: height
    });
  }

  _renderMainSection() {
    return (
      <div className="studio-main-section" style={{ position: 'relative' }}>
        <div className="container">
          <div className="columns is-full-height-desktop">
            <div
              className="column display-flex justify-content-center flex-direction-column"
              style={{ flex: 1 }}
            >
              <Animation delay={300}>
                <h1 className="page-title is-text-center">
                  <FormattedMessage id="studio.title" />
                </h1>
              </Animation>
            </div>
          </div>
          <Animation
            animation="slideUp"
            distance="20%"
            className="studio-image"
            delay={600}
          >
            <div className="columns">
              <div className="column is-12">
                <Responsive min="tablet">
                  <Image
                    src={studio}
                    srcSet={studio2 + ' 2x, ' + studio3 + ' 3x'}
                  />
                </Responsive>
                <Responsive max="landscape">
                  <Image
                    src={studioMobile}
                    srcSet={studioMobile2 + ' 2x, ' + studioMobile3 + ' 3x'}
                  />
                </Responsive>
              </div>
            </div>
          </Animation>
        </div>
      </div>
    );
  }

  _renderHowAnimation() {
    const defaultOptions = {
      loop: false,
      autoplay: false,
      animationData: howWeDo,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid meet'
      }
    };

    return (
      <div className="column is-8 is-4-tablet is-3-desktop">
        <div className="how-animation">
          <Lottie
            ref={anim => {
              this.anim = anim;
            }}
            options={defaultOptions}
            isPaused={!this.state.animationRunning}
            width="100%"
            height="100%"
            direction={this.state.animationReverse ? -1 : 1}
            speed={this.state.animationSpeed}
            eventListeners={[
              {
                eventName: 'enterFrame',
                callback: event => {
                  if (!this.anim) return;

                  let frame = event.currentTime;
                  this.frame = frame;

                  if (this.state.animationReverse) {
                    if (
                      frame <= this.frameBreakpoints[this.state.howDoSelected]
                    ) {
                      this.setState({
                        animationRunning: false
                      });
                    }
                  } else {
                    if (this.state.howDoSelected === 4) {
                      if (
                        frame >=
                        this.frameBreakpoints[this.state.howDoSelected][0]
                      ) {
                        this.setState({
                          animationSpeed: 1
                        });
                      }
                      if (
                        frame >=
                        this.frameBreakpoints[this.state.howDoSelected][1]
                      ) {
                        this.anim.anim.goToAndPlay(
                          this.frameBreakpoints[this.state.howDoSelected][0],
                          true
                        );
                      }
                    } else if (
                      frame >= this.frameBreakpoints[this.state.howDoSelected]
                    ) {
                      this.setState({
                        animationRunning: false
                      });
                    }
                  }
                }
              }
            ]}
          />
        </div>
      </div>
    );
  }

  _renderHowSlider() {
    let wrapper = this;
    let settings = {
      speed: 300,
      slidesToShow: 1.01,
      slidesToScroll: 1,
      swipeToSlide: true,
      focusOnSelect: true,
      infinite: false,
      dots: true,
      arrows: false,
      customPaging: function(i) {
        return (
          <div className="slider-dot">
            {i}
            <div className="slider-color" />
          </div>
        );
      },
      afterChange: function(i) {
        let breakpoint = wrapper.frameBreakpoints[i];
        if (i === 4) {
          breakpoint = wrapper.frameBreakpoints[0];
        }
        if (wrapper.state.howDoSelected !== i) {
          wrapper.setState({
            howDoSelected: i,
            animationRunning: true,
            animationReverse: wrapper.frame > wrapper.frameBreakpoints[i],
            animationSpeed: Math.abs(
              (breakpoint - wrapper.frame) *
                wrapper.frameSpeedMultiplier /
                wrapper.frameTotal
            )
          });
        }
      }
    };

    return (
      <div className="container">
        <Slider className="how-mobile-services-wrapper" {...settings}>
          <div className="how-mobile-services">
            <h2>
              <FormattedMessage id="studio.how_we_do_it.strategy.title" />
            </h2>
            <p className="section-text">
              <FormattedMessage id="studio.how_we_do_it.0.description" />
            </p>
          </div>
          <div className="how-mobile-services">
            <h2>
              <FormattedMessage id="studio.how_we_do_it.wireframe.title" />
            </h2>
            <p className="section-text">
              <FormattedMessage id="studio.how_we_do_it.1.description" />
            </p>
          </div>
          <div className="how-mobile-services">
            <h2>
              <FormattedMessage id="studio.how_we_do_it.design.title" />
            </h2>
            <p className="section-text">
              <FormattedMessage id="studio.how_we_do_it.2.description" />
            </p>
          </div>
          <div className="how-mobile-services">
            <h2>
              <FormattedMessage id="studio.how_we_do_it.development.title" />
            </h2>
            <p className="section-text">
              <FormattedMessage id="studio.how_we_do_it.3.description" />
            </p>
          </div>
          <div className="how-mobile-services">
            <h2>
              <FormattedMessage id="studio.how_we_do_it.launch.title" />
            </h2>
            <p className="section-text">
              <FormattedMessage id="studio.how_we_do_it.4.description" />
            </p>
          </div>
        </Slider>
      </div>
    );
  }

  _renderHowSliderTablet() {
    let wrapper = this;
    let settings = {
      speed: 300,
      slidesToShow: 1.1,
      slidesToScroll: 1,
      swipeToSlide: true,
      focusOnSelect: true,
      infinite: false,
      dots: true,
      arrows: false,
      customPaging: function(i) {
        return (
          <div className="slider-dot">
            {i}
            <div className="slider-color" />
          </div>
        );
      },
      afterChange: function(i) {
        let breakpoint = wrapper.frameBreakpoints[i];
        if (i === 4) {
          breakpoint = wrapper.frameBreakpoints[0];
        }
        if (wrapper.state.howDoSelected !== i) {
          wrapper.setState({
            howDoSelected: i,
            animationRunning: true,
            animationReverse: wrapper.frame > wrapper.frameBreakpoints[i],
            animationSpeed: Math.abs(
              (breakpoint - wrapper.frame) *
                wrapper.frameSpeedMultiplier /
                wrapper.frameTotal
            )
          });
        }
      }
    };

    return (
      <div className="column is-7 is-offset-1">
        <Slider className="how-mobile-services-wrapper" {...settings}>
          <div className="how-mobile-services">
            <h2>
              <FormattedMessage id="studio.how_we_do_it.strategy.title" />
            </h2>
            <p className="section-text">
              <FormattedMessage id="studio.how_we_do_it.0.description" />
            </p>
          </div>
          <div className="how-mobile-services">
            <h2>
              <FormattedMessage id="studio.how_we_do_it.wireframe.title" />
            </h2>
            <p className="section-text">
              <FormattedMessage id="studio.how_we_do_it.1.description" />
            </p>
          </div>
          <div className="how-mobile-services">
            <h2>
              <FormattedMessage id="studio.how_we_do_it.design.title" />
            </h2>
            <p className="section-text">
              <FormattedMessage id="studio.how_we_do_it.2.description" />
            </p>
          </div>
          <div className="how-mobile-services">
            <h2>
              <FormattedMessage id="studio.how_we_do_it.development.title" />
            </h2>
            <p className="section-text">
              <FormattedMessage id="studio.how_we_do_it.3.description" />
            </p>
          </div>
          <div className="how-mobile-services">
            <h2>
              <FormattedMessage id="studio.how_we_do_it.launch.title" />
            </h2>
            <p className="section-text">
              <FormattedMessage id="studio.how_we_do_it.4.description" />
            </p>
          </div>
        </Slider>
      </div>
    );
  }

  _renderHow() {
    return (
      <div ref={how => (this.how = how)} className="how-section">
        <div className="container">
          <div className="columns">
            <div className="column is-12 is-5-tablet">
              <h2 className="section-title">
                <FormattedMessage id="studio.how_we_do_it.title" />
              </h2>
            </div>
            <div className="column is-12 is-7-tablet is-6-widescreen">
              <p className="section-text">
                <FormattedMessage id="studio.how_we_do_it.description" />
              </p>
            </div>
          </div>
          <Responsive max="tablet">
            <div className="columns force-mobile">
              {this._renderHowAnimation()}
              <Responsive min="tablet">
                {this._renderHowSliderTablet()}
              </Responsive>
            </div>
          </Responsive>
          <Responsive min="desktop">
            <div className="columns how-we-do-services">
              <Responsive min="desktop">
                {this._renderHowAnimation()}
              </Responsive>
              <div className="column is-12 is-offset-2-desktop is-5-tablet is-3-desktop">
                <ul className="service-list">
                  <li
                    className={
                      this.state.howDoSelected === 0 ? 'is-active' : null
                    }
                    onMouseEnter={() => {
                      if (this.state.howDoSelected !== 0) {
                        this.setState({
                          howDoSelected: 0,
                          animationRunning: true,
                          animationReverse:
                            this.frame > this.frameBreakpoints[0],
                          animationSpeed: Math.abs(
                            (this.frameBreakpoints[0] - this.frame) *
                              this.frameSpeedMultiplier /
                              this.frameTotal
                          )
                        });
                      }
                    }}
                  >
                    <FormattedMessage id="studio.how_we_do_it.strategy.title" />
                  </li>
                  <li
                    className={
                      this.state.howDoSelected === 1 ? 'is-active' : null
                    }
                    onMouseEnter={() => {
                      if (this.state.howDoSelected !== 1) {
                        this.setState({
                          howDoSelected: 1,
                          animationRunning: true,
                          animationReverse:
                            this.frame > this.frameBreakpoints[1],
                          animationSpeed: Math.abs(
                            (this.frameBreakpoints[1] - this.frame) *
                              this.frameSpeedMultiplier /
                              this.frameTotal
                          )
                        });
                      }
                    }}
                  >
                    <FormattedMessage id="studio.how_we_do_it.wireframe.title" />
                  </li>
                  <li
                    className={
                      this.state.howDoSelected === 2 ? 'is-active' : null
                    }
                    onMouseEnter={() => {
                      if (this.state.howDoSelected !== 2) {
                        this.setState({
                          howDoSelected: 2,
                          animationRunning: true,
                          animationReverse:
                            this.frame > this.frameBreakpoints[2],
                          animationSpeed: Math.abs(
                            (this.frameBreakpoints[2] - this.frame) *
                              this.frameSpeedMultiplier /
                              this.frameTotal
                          )
                        });
                      }
                    }}
                  >
                    <FormattedMessage id="studio.how_we_do_it.design.title" />
                  </li>
                  <li
                    className={
                      this.state.howDoSelected === 3 ? 'is-active' : null
                    }
                    onMouseEnter={() => {
                      if (this.state.howDoSelected !== 3) {
                        this.setState({
                          howDoSelected: 3,
                          animationRunning: true,
                          animationReverse:
                            this.frame > this.frameBreakpoints[3],
                          animationSpeed: Math.abs(
                            (this.frameBreakpoints[3] - this.frame) *
                              this.frameSpeedMultiplier /
                              this.frameTotal
                          )
                        });
                      }
                    }}
                  >
                    <FormattedMessage id="studio.how_we_do_it.development.title" />
                  </li>
                  <li
                    className={
                      this.state.howDoSelected === 4 ? 'is-active' : null
                    }
                    onMouseEnter={() => {
                      if (this.state.howDoSelected !== 4) {
                        this.setState({
                          howDoSelected: 4,
                          animationRunning: true,
                          animationReverse:
                            this.frame > this.frameBreakpoints[4][1],
                          animationSpeed:
                            (this.frameBreakpoints[4][0] - this.frame) *
                            this.frameSpeedMultiplier /
                            this.frameTotal
                        });
                      }
                    }}
                  >
                    <FormattedMessage id="studio.how_we_do_it.launch.title" />
                  </li>
                </ul>
              </div>
              <div className="column">
                <div className="service-text-wrapper">
                  <p
                    className="service-text"
                    style={{ opacity: this.state.howDoSelected === 0 ? 1 : 0 }}
                  >
                    <FormattedMessage id="studio.how_we_do_it.0.description" />
                  </p>
                  <p
                    className="service-text"
                    style={{ opacity: this.state.howDoSelected === 1 ? 1 : 0 }}
                  >
                    <FormattedMessage id="studio.how_we_do_it.1.description" />
                  </p>
                  <p
                    className="service-text"
                    style={{ opacity: this.state.howDoSelected === 2 ? 1 : 0 }}
                  >
                    <FormattedMessage id="studio.how_we_do_it.2.description" />
                  </p>
                  <p
                    className="service-text"
                    style={{ opacity: this.state.howDoSelected === 3 ? 1 : 0 }}
                  >
                    <FormattedMessage id="studio.how_we_do_it.3.description" />
                  </p>
                  <p
                    className="service-text"
                    style={{ opacity: this.state.howDoSelected === 4 ? 1 : 0 }}
                  >
                    <FormattedMessage id="studio.how_we_do_it.4.description" />
                  </p>
                </div>
              </div>
            </div>
          </Responsive>
        </div>
        <Responsive max="landscape">{this._renderHowSlider()}</Responsive>
      </div>
    );
  }

  _renderMap() {
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: mapAnim,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid meet'
      }
    };

    return (
      <div
        ref={globally => (this.globally = globally)}
        className="globally-section"
      >
        <div className="container">
          <div className="columns">
            <div className="column is-12 is-5-tablet">
              <h2 className="section-title">
                <FormattedMessage id="studio.globally.title" />
              </h2>
            </div>
            <div className="column is-12 is-7-tablet is-6-widescreen">
              <p className="section-text">
                <FormattedMessage id="studio.globally.description" />
              </p>
              <CountryLoop />
            </div>
          </div>
          <Responsive min="tablet">
            <div style={{ position: 'relative', zIndex: 1, marginTop: 100 }}>
              <Image style={{ position: 'absolute', zIndex: -1 }} src={map} />
              <Lottie
                options={defaultOptions}
                width={this.state.mapWidth}
                height={this.state.mapHeight}
              />
            </div>
          </Responsive>
        </div>
        <Responsive max="landscape">
          <div style={{ marginTop: 60 }}>
            <Image src={mapMobile} />
          </div>
        </Responsive>
      </div>
    );
  }

  render() {
    return (
      <div
        className="main-wrapper"
        style={{
          transition: 'opacity 300ms',
          opacity: this.state.animated ? 1 : 0
        }}
      >
        {this._renderMainSection()}
        <div className="black-studio" />
        <ScrollVisible offset={300}>
          {visible => (
            <div
              ref={culture => {
                this.culture = culture;
              }}
              className="culture-section"
            >
              <div className="container">
                <div className="columns">
                  <div className="column is-12 is-5-tablet">
                    <h2 className="section-title">
                      <FormattedMessage id="studio.culture.title" />
                    </h2>
                  </div>
                  <div className="column is-12 is-7-tablet is-6-widescreen">
                    <p className="section-text">
                      <FormattedMessage id="studio.culture.description" />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </ScrollVisible>
        {this._renderHow()}
        {this._renderMap()}
        <div className="who-section">
          <div className="container">
            <div className="columns">
              <div className="column is-12 is-5-tablet">
                <h2 className="section-title">
                  <FormattedMessage id="studio.who_we_are.title" />
                </h2>
              </div>
              <div className="column is-12 is-7-tablet is-6-widescreen">
                <p className="section-text">
                  <FormattedMessage id="studio.who_we_are.description" />
                </p>
              </div>
            </div>
            <Responsive max="landscape">
              <div className="columns force-mobile studio-gallery">
                <div className="column is-12">
                  <Image
                    src={studio1Mobile}
                    srcSet={studio1Mobile2 + ' 2x, ' + studio1Mobile3 + ' 3x'}
                  />
                </div>
                <div className="column is-12">
                  <Image
                    src={studio2Mobile}
                    srcSet={studio2Mobile2 + ' 2x, ' + studio2Mobile3 + ' 3x'}
                  />
                </div>
                <div className="column is-6">
                  <Image
                    src={studio3Mobile}
                    srcSet={studio3Mobile2 + ' 2x, ' + studio3Mobile3 + ' 3x'}
                  />
                </div>
                <div className="column is-6">
                  <Image
                    src={studio4Mobile}
                    srcSet={studio4Mobile2 + ' 2x, ' + studio4Mobile3 + ' 3x'}
                  />
                </div>
                <div className="column is-12">
                  <Image
                    src={studio5Mobile}
                    srcSet={studio5Mobile2 + ' 2x, ' + studio5Mobile3 + ' 3x'}
                  />
                </div>
              </div>
            </Responsive>
            <Responsive min="tablet">
              <div className="columns studio-gallery">
                <div className="column is-5 left-gallery">
                  <div className="columns">
                    <div className="column is-10 is-offset-2">
                      <Image
                        src={studio4_1}
                        srcSet={studio4_2 + ' 2x, ' + studio4_3 + ' 3x'}
                      />
                    </div>
                  </div>
                  <Image
                    src={studio3_1}
                    srcSet={studio3_2 + ' 2x, ' + studio3_3 + ' 3x'}
                  />
                </div>
                <div className="column is-7">
                  <Image
                    src={studio1_1}
                    srcSet={studio1_2 + ' 2x, ' + studio1_3 + ' 3x'}
                  />
                  <div className="columns">
                    <div className="column is-10">
                      <Image
                        src={studio2_1}
                        srcSet={studio2_2 + ' 2x, ' + studio2_3 + ' 3x'}
                      />
                    </div>
                  </div>
                  <div className="columns">
                    <div className="column is-7">
                      <Image
                        src={studio5_1}
                        srcSet={studio5_2 + ' 2x, ' + studio5_3 + ' 3x'}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Responsive>
          </div>
        </div>
        <div
          ref={footer => {
            this.footer = footer;
          }}
          className="footer"
        >
          <ScrollVisible>
            {visible => <Footer visible={visible} />}
          </ScrollVisible>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  locale: state.i18n.locale
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      changeMenuColor
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Studio);

import React, { PureComponent } from 'react';

export default class InstragramIcon extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="22"
        height="22"
        viewBox="0 0 22 22"
      >
        <path
          fill="#FFF"
          d="M11.001 0C8.014 0 7.639.013 6.465.066c-1.17.054-1.97.24-2.67.511a5.386 5.386 0 0 0-1.948 1.269 5.4 5.4 0 0 0-1.27 1.948c-.272.7-.458 1.5-.51 2.67C.013 7.637 0 8.013 0 11c0 2.987.013 3.361.066 4.535.054 1.17.24 1.97.512 2.67a5.39 5.39 0 0 0 1.268 1.948 5.386 5.386 0 0 0 1.948 1.27c.7.272 1.5.457 2.67.51C7.638 21.988 8.012 22 11 22c2.987 0 3.361-.013 4.534-.066 1.172-.054 1.972-.24 2.672-.512a5.382 5.382 0 0 0 1.947-1.27 5.4 5.4 0 0 0 1.27-1.947c.27-.7.456-1.5.51-2.67C21.987 14.36 22 13.987 22 11s-.014-3.362-.066-4.536c-.055-1.17-.241-1.97-.512-2.67a5.399 5.399 0 0 0-1.27-1.948A5.376 5.376 0 0 0 18.206.577c-.701-.272-1.501-.457-2.672-.51C14.36.012 13.986 0 10.998 0H11zm-.987 1.982h.987c2.937 0 3.285.01 4.445.064 1.073.049 1.655.228 2.042.378.514.2.88.438 1.264.823.385.385.624.752.824 1.265.15.387.33.97.378 2.042.053 1.16.065 1.508.065 4.443 0 2.936-.012 3.284-.065 4.444-.049 1.073-.228 1.655-.378 2.042-.2.513-.439.879-.824 1.264-.385.385-.75.623-1.264.822-.387.151-.97.33-2.042.38-1.16.052-1.508.063-4.445.063-2.937 0-3.285-.011-4.445-.064-1.072-.05-1.655-.228-2.043-.379-.513-.2-.88-.438-1.265-.823a3.41 3.41 0 0 1-.823-1.264c-.15-.387-.33-.97-.379-2.042-.052-1.16-.063-1.508-.063-4.445 0-2.938.01-3.284.063-4.444.05-1.072.229-1.654.38-2.042.199-.513.437-.88.822-1.265a3.414 3.414 0 0 1 1.265-.823c.388-.152.97-.33 2.043-.38 1.015-.045 1.408-.06 3.458-.061v.002zm6.859 1.827a1.32 1.32 0 1 0 0 2.64 1.32 1.32 0 0 0 0-2.64zM11 5.35a5.65 5.65 0 1 0 0 11.298 5.65 5.65 0 0 0 0-11.298zm0 1.982a3.667 3.667 0 1 1 0 7.334 3.667 3.667 0 0 1 0-7.334z"
        />
      </svg>
    );
  }
}
